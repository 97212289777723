import React from 'react';

export default class OffClick extends React.Component {
    constructor(props){
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }
    
    componentDidMount(){
        document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.handleClick, false)
    }

    handleClick(e) {
        if(!this.refs.child_0.contains(e.target))
            this.props.handler(e)
    }

    render(){
        return React.Children.map(this.props.children, (child, i) => {
            return React.cloneElement(child, {
                ref: 'child_'+i
            })
        })
    }
}

OffClick.defaultProps = {
    handler: () => {}
}